import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
import BlogCard from '@components/BlogCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Over the last couple of years, we've started pushing our application state management to the browser. Managing state can be a large part of what makes our job as UI developers challenging. We've made great strides with patterns such as `}<a parentName="p" {...{
        "href": "http://redux.js.org/"
      }}>{`Redux`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#usereducer"
      }}>{`useReducer`}</a>{` hook in React.`}</p>
    <p>{`I like Redux and `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{` and have found them a very effective method for handling state management. However, there are many benefits to using `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Finite-state_machine"
      }}>{`finite state machines`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/0167642387900359/pdf"
      }}>{`statecharts`}</a>{` as a UI state management solution. The main advantage - you can `}<em parentName="p">{`declaratively`}</em>{` define the UI logic behind each state and restrict your states (no unknown or unintended states).`}</p>
    <BlogCard mdxType="BlogCard">
  <strong>Note:</strong> This is not intended to be an introduction to reducers
  or state machines, rather a light comparison between the two used in the
  context of React. If you are new to either concept, here are a few resources
  to dive into first
    </BlogCard>
    <ul>
      <li parentName="ul">{`React `}<a parentName="li" {...{
          "href": "https://reactjs.org/docs/hooks-reference.html#usereducer"
        }}>{`useReducer`}</a>{` hook`}</li>
      <li parentName="ul">{`Xstate `}<a parentName="li" {...{
          "href": "https://xstate.js.org/docs/about/concepts.html"
        }}>{`concepts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://xstate.js.org/docs/packages/xstate-fsm/"
        }}>{`@xstate/fsm`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://xstate.js.org/docs/packages/xstate-react/"
        }}>{`@xstate/react`}</a></li>
    </ul>
    <h2>{`Using a reducer`}</h2>
    <p>{`Here is a simple toggle button example showcasing the reducer pattern with the `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{` hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE':
      return state === 'active' ? 'inactive' : 'active';
    default:
      return state;
  }
};

const Toggler = () => {
  const [state, dispatch] = useReducer(reducer, 'inactive');

  return (
    <button onClick={() => dispatch({ type: 'TOGGLE' })}>
      {state === 'inactive' ? 'Activate' : 'Deactivate'}
    </button>
  );
};

export default Toggler;
`}</code></pre>
    <p>{`A quick breakdown, the `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{` hook accepts two arguments: our reducer function and the initial state. The job of the reducer function is to return the new state when our `}<inlineCode parentName="p">{`dispatch`}</inlineCode>{` function is called. The new state will depend on the `}<inlineCode parentName="p">{`action`}</inlineCode>{` object that is dispatched.`}</p>
    <p>{`In this example, there is only one action type that our reducer will respond (update) to, `}<inlineCode parentName="p">{`TOGGLE`}</inlineCode>{`. When `}<inlineCode parentName="p">{`dispatch`}</inlineCode>{` is executed with our action object of `}<inlineCode parentName="p">{`{ type: 'TOGGLE' }`}</inlineCode>{`, the reducer contains the conditional logic that will flip our state value between `}<inlineCode parentName="p">{`'active'`}</inlineCode>{` and `}<inlineCode parentName="p">{`'inactive'`}</inlineCode>{`.`}</p>
    <p>{`It works, but the switch statement and internal conditionals make it very procedural. To me, this seems manual and prone to leaving edge-cases unhandled.`}</p>
    <h2>{`A simple FSM alternative`}</h2>
    <p>{`Let's migrate our reducer pattern over to using a finite state machine. The `}<inlineCode parentName="p">{`@xstate/react`}</inlineCode>{` package is a wonderful way to start integrating the full power of XState into your React application. However, if your state management needs are simple (such as our basic reducer), the `}<inlineCode parentName="p">{`@xstate/fsm`}</inlineCode>{` might be a sufficient start. This package is XState's minimal solution for creating finite state machines – a `}<em parentName="p">{`1kb`}</em>{` implementation of XState.`}</p>
    <p>{`Instead of including all of XState plus the React package helpers, we can use the much smaller `}<inlineCode parentName="p">{`@xstate/fsm`}</inlineCode>{` with our own `}<inlineCode parentName="p">{`useMachine`}</inlineCode>{` hook.`}</p>
    <p>{`Using a React hook makes it easier to use state machines with function components. As suggested in the `}<a parentName="p" {...{
        "href": "https://xstate.js.org/docs/recipes/react.html#hooks"
      }}>{`Usage with React`}</a>{` section of the XState docs, there are other hook-based solutions you can use or you can implement your own simple hook to interpret and use XState machines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useState, useMemo, useEffect } from 'react';
import { interpret } from '@xstate/fsm';

export function useMachine(machine) {
  // Keep track of the current machine state
  const [current, setCurrent] = useState(machine.initialState);

  // Interpret the machine and start the service (only once!)
  const service = useMemo(() => interpret(machine).start(), [machine]);

  useEffect(() => {
    // Subscribe to state changes
    service.subscribe((state) => {
      // Update the current machine state when
      // a transition occurs
      if (state.changed) {
        setCurrent(state);
      }
    });

    // Stop the service when the component unmounts
    return () => service.stop();
  }, [service]);

  return [current, service.send];
}
`}</code></pre>
    <p>{`Let's break it down a bit:`}</p>
    <ol>
      <li parentName="ol">{`Our machine's current state value is kept with the `}<inlineCode parentName="li">{`useState`}</inlineCode>{` hook.`}</li>
      <li parentName="ol">{`The provided machine configuration is `}<a parentName="li" {...{
          "href": "https://xstate.js.org/docs/packages/xstate-fsm/#api"
        }}>{`interpreted`}</a>{` and then `}<a parentName="li" {...{
          "href": "https://xstate.js.org/docs/packages/xstate-fsm/#api"
        }}>{`started`}</a>{`.`}</li>
      <li parentName="ol">{`A service, created from `}<inlineCode parentName="li">{`interpret(machine)`}</inlineCode>{`, can be `}<a parentName="li" {...{
          "href": "https://xstate.js.org/docs/packages/xstate-fsm/#api"
        }}>{`subscribed`}</a>{` to via the `}<inlineCode parentName="li">{`.subscribe(...)`}</inlineCode>{` method.`}</li>
      <li parentName="ol">{`Our subscription is notified of all state changes, updating our hook state when a transition occurs.`}</li>
      <li parentName="ol">{`Our hook returns an array containing the current state and our service's `}{`[send]`}{` method so we can send transitions to our machine.`}</li>
    </ol>
    <h2>{`Using our minimal machine hook`}</h2>
    <p>{`Now that we have our custom `}<inlineCode parentName="p">{`useMachine`}</inlineCode>{` hook, let's put it into use in our Toggler component!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { createMachine } from '@xstate/fsm';
import { useMachine } from '../hooks/useMachine';

const toggleMachine = createMachine({
  id: 'toggle',
  initial: 'inactive',
  states: {
    inactive: { on: { TOGGLE: 'active' } },
    active: { on: { TOGGLE: 'inactive' } },
  },
});

const Toggler = () => {
  const [state, send] = useMachine(toggleMachine);

  return (
    <button onClick={() => send('TOGGLE')}>
      {state.value === 'inactive' ? 'Activate' : 'Deactivate'}
    </button>
  );
};

export default Toggler;
`}</code></pre>
    <p>{`With a single, simple hook and the tiny `}<inlineCode parentName="p">{`@xstate/fsm`}</inlineCode>{` library, we have migrated our reducer pattern to now using a state machine. We have replaced the reducer switch statement of uncertainty with a finite set of states and well-defined transitions.`}</p>
    <h2>{`Visualizing our state machine`}</h2>
    <p>{`XState also has a visualizer that allows you to load and preview your state machine configuration. The visualizer can create a `}<a parentName="p" {...{
        "href": "https://xstate.js.org/viz/?gist=8920aced5b898be4f270e82171434d96"
      }}>{`sharable link`}</a>{` of our toggle machine.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.513513513513516%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b8045c8f6752ae1591aa193d50bec5db/cbe2e/visualizer-toggle-machine.webp 148w", "/static/b8045c8f6752ae1591aa193d50bec5db/3084c/visualizer-toggle-machine.webp 295w", "/static/b8045c8f6752ae1591aa193d50bec5db/a3432/visualizer-toggle-machine.webp 350w"],
            "sizes": "(max-width: 350px) 100vw, 350px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b8045c8f6752ae1591aa193d50bec5db/12f09/visualizer-toggle-machine.png 148w", "/static/b8045c8f6752ae1591aa193d50bec5db/e4a3f/visualizer-toggle-machine.png 295w", "/static/b8045c8f6752ae1591aa193d50bec5db/13ae7/visualizer-toggle-machine.png 350w"],
            "sizes": "(max-width: 350px) 100vw, 350px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b8045c8f6752ae1591aa193d50bec5db/13ae7/visualizer-toggle-machine.png",
            "alt": "Our toggle machine visualized",
            "title": "Our toggle machine visualized",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Instead of using a framework-specific API such as `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{`, our machine state logic is completely portable and can be used independently of React in any framework.`}</p>
    <h2>{`Upgrade ready`}</h2>
    <p>{`This is only a small step towards replacing reducer logic with a more declarative machine-based pattern. If you want to use `}<em parentName="p">{`all`}</em>{` the statechart features such as nested states, parallel states, history states, activities, invoked services, delayed transitions, and transient transitions, we'll need to level up to the full XState library.`}</p>
    <p>{`First, let's update our packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm rm @xstate/fsm
npm install -S xstate @xstate/react
`}</code></pre>
    <p>{`Now, we can update our Toggler component code:`}</p>
    <ol>
      <li parentName="ol">{`Replace `}<inlineCode parentName="li">{`@xstate/fsm`}</inlineCode>{` with `}<inlineCode parentName="li">{`xstate`}</inlineCode></li>
      <li parentName="ol">{`Replace the `}<inlineCode parentName="li">{`createMachine`}</inlineCode>{` function with the `}<inlineCode parentName="li">{`Machine`}</inlineCode>{` factory function from `}<inlineCode parentName="li">{`xstate`}</inlineCode></li>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`@xstate/react`}</inlineCode>{` hook instead of our custom `}<inlineCode parentName="li">{`useMachine`}</inlineCode>{` hook`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { Machine } from 'xstate';
import { useMachine } from '@xstate/react';

const toggleMachine = Machine({
  id: 'toggle',
  initial: 'inactive',
  states: {
    inactive: { on: { TOGGLE: 'active' } },
    active: { on: { TOGGLE: 'inactive' } },
  },
});

const Toggler = () => {
  const [state, send] = useMachine(toggleMachine);

  return (
    <button onClick={() => send('TOGGLE')}>
      {state.value === 'inactive' ? 'Activate' : 'Deactivate'}
    </button>
  );
};

export default Toggler;
`}</code></pre>
    <p>{`That's it! Now we have all the more advanced capabilities of XState without having to make a single change to our state logic.`}</p>
    <p>{`Here is an example `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/xstate-fsm-vs-react-comparison-mw2ty"
      }}>{`CodeSandbox`}</a>{` comparing the `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{` method versus the `}<inlineCode parentName="p">{`useMachine`}</inlineCode>{` hook with `}<inlineCode parentName="p">{`@xstate/fsm`}</inlineCode>{`.`}</p>
    <iframe src="https://codesandbox.io/embed/xstate-fsm-vs-react-comparison-mw2ty?fontsize=14&hidenavigation=1&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="xstate-fsm-vs-react-comparison" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      